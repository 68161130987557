var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.$can("read", "firmwaremanagement")
    ? _c(
        "div",
        { staticClass: "mb-2" },
        [
          _c("firmware-list-add-new", {
            attrs: {
              "is-add-new-firmware-sidebar-active":
                _vm.isAddNewFirmwareSidebarActive
            },
            on: {
              "update:isAddNewFirmwareSidebarActive": function($event) {
                _vm.isAddNewFirmwareSidebarActive = $event
              },
              "update:is-add-new-firmware-sidebar-active": function($event) {
                _vm.isAddNewFirmwareSidebarActive = $event
              },
              refetchdata: _vm.refreshFirmwareList
            }
          }),
          _c(
            "b-card",
            { staticClass: "mb-0", attrs: { "no-body": "" } },
            [
              _c("firmware-list-filters", {
                attrs: {
                  "device-type": _vm.deviceType,
                  "device-type-options": _vm.deviceTypeOptions
                },
                on: {
                  "update:deviceType": function($event) {
                    _vm.deviceType = $event
                  },
                  "update:device-type": function($event) {
                    _vm.deviceType = $event
                  }
                }
              }),
              _c(
                "div",
                { staticClass: "m-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start mb-1 mb-md-0",
                          attrs: { cols: "12", md: "6" }
                        },
                        [
                          _c("label", [_vm._v("Show")]),
                          _c("v-select", {
                            staticClass:
                              "per-page-selector d-inline-block mx-50",
                            attrs: {
                              dir: _vm.$store.state.appConfig.isRTL
                                ? "rtl"
                                : "ltr",
                              options: _vm.perPageOptions,
                              clearable: false
                            },
                            model: {
                              value: _vm.perPage,
                              callback: function($$v) {
                                _vm.perPage = $$v
                              },
                              expression: "perPage"
                            }
                          }),
                          _c("label", [_vm._v("entries")]),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                variant: "primary",
                                disabled: this.$store.state.firmware
                                  .fetchingFirmwareList
                              },
                              on: { click: _vm.refreshFirmwareList }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "RefreshCcwIcon" }
                              }),
                              _c("span", { staticClass: "text-nowrap ml-1" }, [
                                _vm._v("Refresh")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "b-button",
                            {
                              staticClass: "ml-2",
                              attrs: { variant: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.isAddNewFirmwareSidebarActive = true
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "FilePlusIcon" }
                              }),
                              _c("span", { staticClass: "text-nowrap ml-1" }, [
                                _vm._v("Add Firmware")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-col", { attrs: { cols: "12", md: "6" } }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center justify-content-end"
                          },
                          [
                            _c("b-form-input", {
                              staticClass: "d-inline-block mr-1",
                              attrs: { placeholder: "Search..." },
                              model: {
                                value: _vm.searchQuery,
                                callback: function($$v) {
                                  _vm.searchQuery = $$v
                                },
                                expression: "searchQuery"
                              }
                            })
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c("b-table", {
                ref: "refFirmwareListTable",
                staticClass: "position-relative",
                attrs: {
                  items: _vm.fetchFirmware,
                  responsive: "",
                  fields: _vm.tableColumns,
                  "primary-key": "version",
                  "sort-by": _vm.sortBy,
                  "show-empty": "",
                  "empty-text": "",
                  "sort-desc": _vm.isSortDirDesc,
                  busy: _vm.isBusy
                },
                on: {
                  "update:sortBy": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sort-by": function($event) {
                    _vm.sortBy = $event
                  },
                  "update:sortDesc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:sort-desc": function($event) {
                    _vm.isSortDirDesc = $event
                  },
                  "update:busy": function($event) {
                    _vm.isBusy = $event
                  }
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "cell(version)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-media",
                            { attrs: { "vertical-align": "center" } },
                            [_vm._v(" " + _vm._s(data.item.version) + " ")]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(device)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c("span", [_vm._v(_vm._s(data.item.device))])
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(checksum)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [_vm._v(_vm._s(data.item.checksum))]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(date_uploaded)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "text-nowrap" }, [
                            _c(
                              "span",
                              { staticClass: "align-text-top text-capitalize" },
                              [_vm._v(_vm._s(data.item.dt_created))]
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "cell(actions)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-dropdown",
                            {
                              attrs: {
                                variant: "link",
                                "no-caret": "",
                                right: _vm.$store.state.appConfig.isRTL
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "button-content",
                                    fn: function() {
                                      return [
                                        _c("feather-icon", {
                                          staticClass: "align-middle text-body",
                                          attrs: {
                                            icon: "MoreVerticalIcon",
                                            size: "16"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [
                              _vm.$can("read", "firmwaremanagement")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadFirmware(data.item)
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "DownloadIcon" }
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle ml-50" },
                                        [_vm._v("Download")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.$can("delete", "firmwaremanagement")
                                ? _c(
                                    "b-dropdown-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteFirmware(data.item)
                                        }
                                      }
                                    },
                                    [
                                      _c("feather-icon", {
                                        attrs: { icon: "TrashIcon" }
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "align-middle ml-50" },
                                        [_vm._v("Delete")]
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3042517675
                )
              }),
              _c(
                "div",
                { staticClass: "mx-2 mb-2" },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.dataMeta.from) +
                                " to " +
                                _vm._s(_vm.dataMeta.to) +
                                " of " +
                                _vm._s(_vm.dataMeta.of) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0",
                            attrs: {
                              "total-rows": _vm.totalItems,
                              "per-page": _vm.perPage,
                              "first-number": "",
                              "last-number": "",
                              "prev-class": "prev-item",
                              "next-class": "next-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }